<template>
    <img v-if="isBase64" :src="document" alt="img" class="responsive" :class="customClass"/>
    <img v-else :src="temporaryLinkUri" alt="img"  class="responsive" :class="customClass"/>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "S3ImageGenerator",
  props: [
    "isBase64",
    "document",
    "customClass"
  ],
  data() {
    return {
      temporaryLinkUri: ""
    }
  },
  mounted() {
    this.generateTemporaryLink()
      .catch(error => {
        
      })
      .finally(() => {
        
      })
      .then(s3Data => {
        if (s3Data && s3Data.hasOwnProperty('status') && s3Data.status === 200) {
          this.temporaryLinkUri = s3Data.data.data.url;
        } else {
          
        }
      })
  },
  methods: {
    ...mapActions('s3ServiceModule', [
      'fetchFile'
    ]),

    async generateTemporaryLink() {
      try {
        if ( !this.isBase64 )
          return await this.fetchFile(this.document);
      }
      catch (e) {
        
      }
    }
  }
}
</script>