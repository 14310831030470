<template>

  <div class="flex justify-end w-full my-5" v-if="errors.items.length > 0">
    <!--- validation is displayed here, userform is name of the form--->
    <div class="validation-popup px-6 py-6 flex flex-row items-start">
    <img :src="warningIcon" class="icon-container warning-icon"/>
      <div class="pl-4">        

        <h4 class="font-semibold mb-4 text-lg md:text-xl">Mandatory fields missing</h4>
        <p class="mb-0 font-base">Please fill in the below fields before proceeding.</p>

        <div class="mt-4 mb-2">
          <ul>
            <li v-for="error in errorsLists" class="font-base">
              <a class="field-name capitalize"  v-scroll-to="{ el: getErrorFieldId(error.field), offset: -100 }">{{ error.field }}</a> -
              <span class="">{{formName}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Popup",
  props: {
    formName: {
      type: String,
      required: true
    },
    errorsLists: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      warningIcon: require("@/assets/images/icons/alert-danger-circle.svg")
    }
  },
  methods:{
    getErrorFieldId(id){
      return '#'+id
    }
  }

}
</script>